import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useTranslation } from 'react-i18next';
import { GlobalSet } from '../../global/GlobalProvider';
import { Button } from 'react-bootstrap';

const BasicDatatable = () => {
  const { t } = useTranslation();
  const { CurrencySymbol } = GlobalSet();
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  /*.....income data......*/
  const [income, setIncome] = useState([]);
  const fetchData = () => {
    const auth_token = localStorage.getItem('auth_token');
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      currency: selectedCurrency,
    }
    if (auth_token !== null) {
      axios.post('/all-ag-codes', data)
        .then(response => {
          setIncome(response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [showForm, setShowForm] = useState(false);
  const [depositInput, setDepositInput] = useState({
    amount: '',
    password: '',
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setDepositInput({ ...depositInput, [e.target.name]: e.target.value });
  }

  const promocodeSubmit = (e) => {
    e.preventDefault();
    const auth_token = localStorage.getItem('auth_token');
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      amount: depositInput.amount,
      password: depositInput.password,
      currency: selectedCurrency,
    }

    if (auth_token !== null  && !loading) {
      setLoading(true);
      axios.post(`/create-ag-codes`, data).then(res => {
        setLoading(false);
        if (res.data.status === 'success') {
          swal("Success", res.data.message, "success");
          fetchData();

          setDepositInput({
            ...depositInput,
            amount: '',
            password: '',
            error_list: []
          });
        } else if (res.data.status === 'fail') {
          swal("Warning", res.data.message, "warning");
        } else {
          setDepositInput({ ...depositInput, error_list: res.data.validation_error });
        }
        setLoading(false);
      });
    }
  }

  const handleShowButtonClick = () => {
    setShowForm(true);
  };

  const handleHideButtonClick = () => {
    setShowForm(false);
  };

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      swal("Copied!", `${code} has been copied to clipboard.`, "success");
    }).catch(err => {
      swal("Failed!", "Failed to copy the code.", "error");
    });
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Create Agent Code</h4>
          {!showForm ? (
            <button onClick={handleShowButtonClick} type="button" className="me-2 btn btn-warning btn-rounded">
              <span className="btn-icon-start text-info"><i className="fa fa-plus color-info"></i></span>Add
            </button>
          ) : (
            <button onClick={handleHideButtonClick} type="button" className="me-2 btn btn-warning btn-rounded">
              <span className="btn-icon-start text-info"><i className="fa fa-plus color-info"></i></span>Add
            </button>
          )}
        </div>
        <div className="card-body">
          {!showForm ? (
            ""
          ) : (
            <div>
              <div className="col-xl-12 col-lg-12 mb-3">
                <div className="basic-form">
                  <form onSubmit={promocodeSubmit} className="needs-validation" noValidate="">
                    <div className="row">

                      <div className="col-sm-4">
                        <div className="input-group mb-3 input-warning">
                          <input
                            type="number"
                            className="form-control"
                            placeholder={`Enter ${selectedCurrency} Amount`}
                            id="amount"
                            required
                            name="amount"
                            onChange={handleInput}
                            value={depositInput.amount}
                          />
                          <span className="input-group-text">{CurrencySymbol}</span>
                        </div>
                        <span className="text-danger">{depositInput.error_list.amount}</span>
                      </div>
                    


                      <div className="col-sm-5">
                       <div className="input-group transparent-append mb-2">
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleInput} 
                            value={depositInput.password}
                          />
                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >
                          {" "}
                          {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                        
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                          </div> 
                           <span className="text-primary">{depositInput.error_list.password}</span>
                        </div>
                     </div> 

                      <div className="col-sm-3 mt-2 mt-sm-0">
                        <button type="submit" className="btn btn-warning mb-2">
                            {loading ? 'Submitting...' : 'Get Agent Code'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      SL
                    </th>
                    <th className="sorting_asc" style={{ width: "177px" }}>
                      Agent Code
                    </th>
                    <th className="sorting" style={{ width: "278px" }}>
                      Code Value
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Status
                    </th>
                    <th className="sorting" style={{ width: "128px" }}>
                      Create Time
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {income.map((incomerow, index) => (
                    <tr key={incomerow.id} className="odd" role="row">
                      <td className="sorting_1">{index + 1}</td>
                      <td
                        className="sorting_1"
                        onClick={() => copyToClipboard(incomerow.code)}
                        style={{ cursor: 'pointer' }}
                      >
                        {incomerow.code}
                      </td>
                      <td className="sorting_1">{CurrencySymbol}{incomerow.point} </td>
                      <td className="sorting_1">
                        <button
                          className={`btn btn-sm ${incomerow.status === 1 ? "btn-success" : "btn-danger"}`}
                        >
                          {incomerow.status === 1 ? "Active" : "Used"}
                        </button>
                      </td>
                      <td className="sorting_1">{incomerow.date}</td>
                    </tr>
                  ))}
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
